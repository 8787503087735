import axios from "axios"

const generatePdf = async (data: {title?: string, body: string}) => {
    const formattedPdf = await axios.post("https://zolomon.ru/api/tools/pdf", {title: data.title, body: data.body, key: "c3a5b1029a8589a0ab7cc11f395117c3"}, {responseType: "blob"})
    const resolvedPdf = formattedPdf.data
    if (resolvedPdf) {
        const element = document.createElement("a")
        element.href = URL.createObjectURL(resolvedPdf)
        element.setAttribute("download", data.title ? data.title + ".pdf" : "")
        element.click()
        element.remove()
    }
}

export default generatePdf